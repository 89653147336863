import React from 'react'
import classNames from 'classnames'

import Layout from '../components/Layout'
import HeroVideo from '../components/HeroVideo'
import Rebrand from '../components/Rebrand'
import Motion from '../components/Motion'
import Logo from '../components/Logo'
import Color from '../components/Color'
import Example from '../components/Example'
import Team from '../components/Team'
import WorkWithUs from '../components/WorkWithUs'
import Loading from '../components/Loading'

import RebrandHeader from '../images/svg/text/txt-rebrand.svg'
import LogoHeader from '../images/svg/text/txt-logo.svg'
import MotionHeader from '../images/svg/text/txt-motion.svg'
import ColorHeader from '../images/svg/text/txt-color.svg'
import WorkWithUsHeader from '../images/svg/text/txt-workwithus.svg'

import TopFooter from '../images/svg/text/txt-top-w.svg'
import RebrandFooter from '../images/svg/text/txt-rebrand-w.svg'
import LogoFooter from '../images/svg/text/txt-logo-w.svg'
import MotionFooter from '../images/svg/text/txt-motion-w.svg'
import ColorFooter from '../images/svg/text/txt-color-w.svg'
import WorkWithUsFooter from '../images/svg/text/txt-workwithus-w.svg'

import '../styles/main.styl'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    let isMsie = false
    if (typeof window !== 'undefined') {
      const ua = window.navigator.userAgent
      const msie = ua.indexOf('MSIE ')
      if (msie > 0 || !!ua.match(/Trident.*rv\:11\./)) {
        isMsie = true
      }
    }

    this.rebrand = React.createRef()
    this.logo = React.createRef()
    this.motion = React.createRef()
    this.color = React.createRef()
    this.workWithUs = React.createRef()
    this.state = {
      headerLinks: [],
      footerLinks: [],
      assetsDidLoad: false,
      isMsie,
    }
    this.handleReadyStateChange = this.handleReadyStateChange.bind(this)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (window.document.readyState === 'complete') {
        this.setState({ assetsDidLoad: true })
      } else {
        window.document.addEventListener(
          'readystatechange',
          this.handleReadyStateChange,
        )
      }
    }
    this.setState({
      headerLinks: [
        { svg: <RebrandHeader />, ref: this.rebrand.current },
        { svg: <LogoHeader />, ref: this.logo.current },
        { svg: <MotionHeader />, ref: this.motion.current },
        { svg: <ColorHeader />, ref: this.color.current },
        { svg: <WorkWithUsHeader />, ref: this.workWithUs.current },
      ],
      footerLinks: [
        { svg: <TopFooter />, ref: null },
        { svg: <RebrandFooter />, ref: this.rebrand.current },
        { svg: <LogoFooter />, ref: this.logo.current },
        { svg: <MotionFooter />, ref: this.motion.current },
        { svg: <ColorFooter />, ref: this.color.current },
        { svg: <WorkWithUsFooter />, ref: this.workWithUs.current },
      ],
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.document.removeEventListener(
        'readystatechange',
        this.handleReadyStateChange,
      )
    }
  }

  handleReadyStateChange(event) {
    const { readyState } = event.target
    if (readyState === 'complete') {
      this.setState({ assetsDidLoad: true })
    }
  }

  render() {
    const { assetsDidLoad, headerLinks, footerLinks } = this.state
    return (
      <React.Fragment>
        <Loading hide={assetsDidLoad} />
        <Layout
          headerLinks={headerLinks}
          footerLinks={footerLinks}
        >
          <div
            className={classNames('marche-content', 'marche-content-grey')}
          >
            <HeroVideo />
            <Rebrand ref={this.rebrand} />
            <Logo ref={this.logo} />
            <Motion ref={this.motion} />
            <Color ref={this.color} />
            <Example />
          </div>
          <div className={classNames('marche-content')}>
            <Team />
          </div>
          <div
            className={classNames('marche-content', 'marche-content-grey')}
          >
            <WorkWithUs ref={this.workWithUs} />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default IndexPage
