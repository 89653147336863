import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Ball from '../images/svg/assets/ball.svg'

const Loading = ({ hide }) => (
  <div className={classNames(
    'marche-loading-view',
    hide ? 'marche-loading-view--hide' : '',
  )}>
    <Ball
      className='marche-loading-view-frame'
      width='256'
      height='256'
    />
  </div>
)

Loading.propTypes = {
  hide: PropTypes.bool.isRequired,
}

export default Loading
